'use client'

import { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'

import {
  Box,
  ButtonPill,
  hex2rgba,
  lineHeights,
  px2rem,
  spacing,
  styles,
} from '@fortum/elemental-ui'

import { ContentfulImage2 } from '@/shared/components/ContentfulImage2'
import { HighlightedHeading } from '@/shared/components/HighlightedHeading'
import { RichText } from '@/shared/components/RichText'
import { StyledHeading } from '@/shared/components/StyledHeading'
import type { HeroSectionEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { Link } from '@/shared/navigation'

export type BasicHeroProps = Omit<HeroSectionEntry, '__typename' | 'sys'>

/**
 * Hero section.
 */
export const BasicHero: React.FC<BasicHeroProps> = (props) => {
  const { colors } = useTheme()
  const headingRef = useRef<HTMLDivElement>(null)
  const [headingWidth, setHeadingWidth] = useState(400)

  useEffect(() => {
    const handleResize = () => {
      if (headingRef.current) {
        setHeadingWidth(headingRef.current.scrollWidth)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [props.title])

  const headingSize = useMemo(() => {
    // eslint-disable-next-line unicorn/prefer-math-min-max
    return headingWidth < 400 ? 400 : headingWidth
  }, [headingWidth])

  const isLongWord = useMemo(() => {
    return props.title && !props.title.includes(' ')
  }, [props.title])

  const gridTemplateColumns = props.image
    ? {
        l: `${px2rem(80)} ${px2rem(headingSize)} 1fr`,
        xl: `1fr ${px2rem(80)} ${px2rem(headingSize)} ${px2rem(960)} 1fr`,
      }
    : {
        l: `${px2rem(80)} ${px2rem(headingSize)} ${px2rem(80)}`,
        xl: `1fr ${px2rem(80)} ${px2rem(headingSize)} ${px2rem(80)} 1fr`,
      }

  return (
    <Box
      display={{ default: 'flex', l: 'grid' }}
      flexDirection="column-reverse"
      gridTemplateColumns={gridTemplateColumns}
      gridTemplateRows="1fr"
      backgroundColor={colors.backgroundLightOnDark}
      position="relative"
    >
      <Box
        gridColumn={{ l: 2, xl: 3 }}
        pt={{ default: props.image ? 'none' : spacing.l, l: spacing.l }}
        pb={spacing.l}
        zIndex={1}
        ph={{ default: spacing.xxs, l: 0 }}
      >
        <Box maxWidth={{ xs: px2rem(330), s: px2rem(480), l: 'none' }} mh="auto">
          {props.title && (
            <div ref={headingRef}>
              <HighlightedHeading
                whiteSpace={{ default: 'normal', l: isLongWord ? 'nowrap' : 'normal' }}
                lineHeight={lineHeights.compact}
                level={1}
                text={props.title}
                mb={spacing.xxs}
              />
            </div>
          )}
          {props.subtitle && (
            <StyledHeading
              tag="p"
              level={5}
              color={colors.textLightOnDark}
              mb={spacing.xxs}
              mt={`-${spacing.xxxs}`}
            >
              {props.subtitle}
            </StyledHeading>
          )}
          <Box textColor={colors.textLightOnDark}>
            <RichText document={props.content} />

            {(props.primaryLink || props.secondaryLink) && (
              <Box
                display="flex"
                flexDirection={{ default: 'column', s: 'row' }}
                mt={spacing.m}
                gap={{ default: spacing.xs, l: spacing.xxs }}
              >
                {props.primaryLink && (
                  <ButtonPill
                    tag={Link}
                    href={getLinkEntryUrl(props.primaryLink)}
                    boxShadow={`0 0 ${px2rem(27)} ${px2rem(8)} rgba(255, 255, 255, 0.3)`}
                    data-testid="hero-primary-button"
                    invert
                    transparent
                  >
                    {props.primaryLink.label}
                  </ButtonPill>
                )}
                {props.secondaryLink && (
                  <ButtonPill
                    tag={Link}
                    href={getLinkEntryUrl(props.secondaryLink)}
                    data-testid="hero-secondary-button"
                    status="secondary"
                    invert
                    transparent
                  >
                    {props.secondaryLink.label}
                  </ButtonPill>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {props.image && (
        <Box
          gridColumn={{ l: 3, xl: '4 / 6' }}
          position="relative"
          height={{ default: px2rem(220), s: px2rem(440), l: '100%' }}
          maxHeight={{ default: px2rem(220), s: px2rem(440), l: '100%' }}
        >
          <ContentfulImage2
            priority
            fill
            image={props.image}
            boxProps={{ maxWidth: px2rem(1400), borderRadius: 0 }}
            maxWidth="1400"
          />
          <Curve />
        </Box>
      )}
    </Box>
  )
}

const Curve = styled.div`
  position: absolute;
  z-index: 0;

  ${styles.breakpointMax.l} {
    right: 0;
    bottom: 0;
    left: 0;
    height: ${px2rem(100)};
    margin-bottom: -2px;
    clip-path: ellipse(60% 140% at 50% 140%);
    ${({ theme }) => css`
      background: linear-gradient(
        to top,
        ${theme.colors.backgroundLightOnDark},
        ${hex2rgba(theme.colors.backgroundLightOnDark, 0.85)}
      );
    `};
  }

  ${styles.breakpointMax.s} {
    height: ${px2rem(60)};
  }

  ${styles.breakpoint.l} {
    top: 0;
    bottom: 0;
    left: 0;
    width: ${px2rem(100)};
    margin-left: -2px;
    background-color: ${({ theme }) => hex2rgba(theme.colors.backgroundLightOnDark, 0.85)};
    clip-path: ellipse(130% 60% at -32% 50%);
    ${({ theme }) => css`
      background: linear-gradient(
        to right,
        ${theme.colors.backgroundLightOnDark},
        ${hex2rgba(theme.colors.backgroundLightOnDark, 0.85)}
      );
    `};
  }
`
